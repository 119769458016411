<template>
    <div class="d-flex flex-column dashboard px-4 py-4 w-100">
        <p class="my-4 custom-title">Manage Pricing</p>

        <div class="card-main-box">
            <div class="card-box" v-for="item in priceDetails" :key="item.id">
                <div class="card-body">
                    <div class="left">
                        <span class="price">${{ item.price }}</span>
                    </div>
                    <div class="right ">
                        <span class="hours"> from : {{ item.min_hours }}</span>
                        <span class="hours" style="margin-left: 1.35rem;"> to : {{ item.max_hours }}</span>
                    </div>
                </div>

                <div class="card-footer">
                    <div class="left">
                        <v-btn plain :ripple="false" color="info" @click="openEditDialog(item.id)">
                            <v-icon size="17">{{ icons.mdiPencilOutline }}</v-icon>
                            Edit
                        </v-btn>
                    </div>
                    <div class="right">
                        <v-btn plain :ripple="false" color="primary" @click="openDeleteDialog(item.id)">
                            <v-icon size="17">{{ icons.mdiTrashCanOutline }}</v-icon>
                            Delete
                        </v-btn>
                    </div>
                </div>
            </div>
            <div class="add-price-box">
                <div class="card-body " style="margin-top: -20px;">
                    <button @click.prevent="openSaveDialog">
                        <div class="inner-btn-div">
                            <v-icon size="48">{{ icons.mdiPlusBoxMultipleOutline }}</v-icon>
                            <span class="sub-title">ADD NEW PRICE</span>
                        </div>
                    </button>

                </div>
            </div>
        </div>


        <!-- Save Pricing Dialog -->
        <v-dialog v-model="saveDialog" persistent width="400">
            <v-card>

                <v-card-title class="text-h5">
                    Add Pricing Detail
                </v-card-title>

                <v-card-text style="padding-top: 24px !important;">
                    <v-form validate-on="submit lazy" @submit.prevent="submit">
                        <v-text-field v-model="pricingObj.amount" outlined label="Amount" small></v-text-field>
                        <v-text-field v-model="pricingObj.minHours" outlined label="Minimum Hours"></v-text-field>
                        <v-text-field v-model="pricingObj.maxHours" outlined label="Maximum Hours"></v-text-field>
                    </v-form>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text color="green-darken-1" @click="closeSaveDialog">
                        Cancel
                    </v-btn>
                    <v-btn text color="primary" @click="savePrice" :loading="dialogLoading">
                        Save
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Edit Pricing Dialog -->
        <v-dialog v-model="editDialog" persistent width="400">
            <v-card>
                <v-card-title class="text-h5">
                    Edit Pricing Detail
                </v-card-title>
                <v-card-text style="padding-top: 24px !important;">
                    <v-form validate-on="submit lazy" @submit.prevent="submit">
                        <v-text-field v-model="pricingObj.amount" outlined label="Amount" small></v-text-field>
                        <v-text-field v-model="pricingObj.minHours" outlined label="Minimum Hours"></v-text-field>
                        <v-text-field v-model="pricingObj.maxHours" outlined label="Maximum Hours"></v-text-field>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text color="green-darken-1" @click="closeEditDialog">
                        Cancel
                    </v-btn>
                    <v-btn text color="primary" @click="editPrice" :loading="dialogLoading">
                        Save
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Delete Pricing Confirmation Dialog -->
        <v-dialog v-model="deleteDialog" persistent width="400">
            <v-card>
                <v-card-title>
                    Do you want to delete ?
                </v-card-title>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text color="green-darken-1" @click="closeDeleteDialog">
                        Cancel
                    </v-btn>
                    <v-btn text color="primary" @click="deletePrice" :loading="dialogLoading">
                        Delete
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>


    </div>
</template>

<script>
import { mdiPencilOutline, mdiTrashCanOutline, mdiPlusBoxMultipleOutline } from '@mdi/js';
import { ref, onMounted, reactive } from "@vue/composition-api";
import axios from '@axios'
export default {
    setup() {
        const selectedPriceID = ref('')
        const pricingObj = reactive({
            amount: 0,
            minHours: 0,
            maxHours: 0
        })

        const priceDetails = ref()
        const dialogLoading = ref(false)
        const saveDialog = ref(false)
        const editDialog = ref(false)
        const deleteDialog = ref(false)

        const startLoading = () => {
            dialogLoading.value = true
        }

        const stopLoading = () => {
            dialogLoading.value = false
        }

        const openSaveDialog = () => {
            saveDialog.value = true
        }
        const openEditDialog = async (id) => {
            try {
                selectedPriceID.value = id
                const { data } = await axios.get(`/wallet/pricing/${selectedPriceID.value}`)
                if (data) {
                    pricingObj.amount = data.price
                    pricingObj.minHours = data.min_hours
                    pricingObj.maxHours = data.max_hours
                }
            } catch (error) {
                console.log(error)
            }
            finally {
                editDialog.value = true
            }
        }
        const openDeleteDialog = (id) => {
            deleteDialog.value = true
            selectedPriceID.value = id
        }

        const closeSaveDialog = () => {
            saveDialog.value = false
        }
        const closeEditDialog = () => {
            editDialog.value = false
            selectedPriceID.value = ''
            pricingObj.amount = 0
            pricingObj.minHours = 0
            pricingObj.maxHours = 0
        }

        const closeDeleteDialog = () => {
            deleteDialog.value = false
            selectedPriceID.value = ''
        }


        const setPriceDetails = (data) => {
            priceDetails.value = data
        }

        const getPricing = async () => {
            const pricingRes = await axios.get('/wallet/pricing')
            setPriceDetails(pricingRes.data)
            return
        }

        const savePrice = async () => {
            try {
                startLoading()
                await axios.post('/wallet/pricing', {
                    price: pricingObj.amount,
                    min_hours: pricingObj.minHours,
                    max_hours: pricingObj.maxHours
                })
                await getPricing()
                closeSaveDialog()
            } catch (error) {
                console.log(error)
                throw error
            }
            finally {
                stopLoading()
                pricingObj.amount = 0
                pricingObj.minHours = 0
                pricingObj.maxHours = 0
                closeSaveDialog()
            }

        }

        const editPrice = async () => {
            try {
                startLoading()
                await axios.patch('/wallet/pricing', {
                    id: parseInt(selectedPriceID.value),
                    price: parseInt(pricingObj.amount),
                    min_hours: parseInt(pricingObj.minHours),
                    max_hours: parseInt(pricingObj.maxHours)
                })
                await getPricing()

            } catch (error) {
                console.log(error)
                throw error
            } finally {
                selectedPriceID.value = ''
                stopLoading()
                closeEditDialog()
            }
        }

        const deletePrice = async () => {
            try {
                startLoading()
                await axios.delete(`/wallet/pricing/${selectedPriceID.value}`)
                await getPricing()
            } catch (error) {
                console.log(error)
                throw error
            }
            finally {
                selectedPriceID.value = ''
                stopLoading()
                closeDeleteDialog()
            }
        }

        onMounted(() => getPricing())


        return {
            pricingObj,

            savePrice,
            saveDialog,
            openSaveDialog,
            closeSaveDialog,

            editPrice,
            editDialog,
            openEditDialog,
            closeEditDialog,

            deletePrice,
            deleteDialog,
            openDeleteDialog,
            closeDeleteDialog,

            dialogLoading,
            startLoading,
            stopLoading,

            priceDetails,
            icons: {
                mdiPencilOutline,
                mdiTrashCanOutline,
                mdiPlusBoxMultipleOutline
            }
        }
    }
}
</script>

<style scoped>
.inner-btn-div {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transform: translateY(10px);
}

.sub-title {
    font-size: 0.8rem;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    margin-top: 0.3rem;
}

.price {
    font-size: 3.5rem;
    letter-spacing: 1.5px;
    text-transform: uppercase;

}

.hours {
    font-size: 1rem;
    letter-spacing: 1.2px;
    /* text-transform: uppercase; */
}

.card-body {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
}

.card-footer {
    display: flex;
}

.card-footer .left,
.card-footer .right,
.card-body .left,
.card-body .right {
    width: 50%;
    text-align: center;
}

.right {
    display: flex;
    flex-direction: column;
}

.card-main-box {
    width: 100%;
    /* display: flex;
    flex-wrap: wrap;
    gap: 1rem; */
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
}

.card-box {
    background-color: #fff;
    width: 100%;
    max-width: 100%;
    /* padding: 20px 30px 10px 30px !important; */
    border-radius: 8px;
    height: 140px;
    padding: 10px;
}

.add-price-box {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    width: 100%;
    max-width: 100%;
    border-radius: 8px;
    height: 140px;
    padding: 10px;
}

.box-title,
.card-heading,
.custom-title {
    font-size: 0.9rem;
    letter-spacing: 2px;
    text-align: left;
    text-transform: uppercase;
}

.custom-title {
    font-size: 2rem;
    letter-spacing: 1.5px;
}


@media only screen and (max-width: 576px) {
    .card-main-box {
        grid-template-columns: repeat(1, 1fr) !important;
    }
}

@media only screen and (max-width: 992px) {
    .card-main-box {
        grid-template-columns: repeat(2, 1fr);
    }
}
</style>